import React from "react"
import Footer from "../components/footer"
import "../styles/legal.less"
import SEO from "../components/SEO"
import { navigate } from "gatsby"
import Header from "../components/header"
const loadText = require("src/utils").loadText
const data = loadText("legal")
const colors = [
  {
    color:
      "linear-gradient(180deg,rgba(255,253,242,1) 0%,rgba(255,249,225,1) 100%)",
    hover: "#FFD322",
  },
  {
    color:
      "linear-gradient(180deg,rgba(250,244,255,1) 0%,rgba(244,230,255,1) 100%)",
    hover: "#CF96FF",
  },
  { color: "#E1F5FF", hover: "#19ADFD" },
  { color: "#E0F9FF", hover: "#00D2FC" },
  {
    color:
      "linear-gradient(180deg,rgba(236,250,249,1) 0%,rgba(222,246,245,1) 100%)",
    hover: "#00CBC1",
  },
  {
    color:
      "linear-gradient(180deg,rgba(245,246,255,1) 0%,rgba(231,234,255,1) 100%)",
    hover: "#5B87FF",
  },
]
const B1 = () => (
  <div className="legal-b1 full-bg">
    <div className="legal-b1-content page-middle">
      j
      {data.links.map((obj, i) => {
        const objIsString = typeof obj === "string"
        const name = objIsString ? obj : obj.name
        const link = objIsString ? "/" + name.split(" ").join("-") : obj.link
        return (
          <div
            className="legal-b1-item"
            onClick={() => {
              navigate(link)
            }}
          >
            <div style={{ background: colors[i].color }} className="normal">
              <div
                className="legal-b1-item-flex"
                style={{
                  backgroundImage: `url("/legal/${i + 1}-bg.svg")`,
                  backgroundPosition: "bottom",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="font-28 semi-bold t-color name">{name}</div>
              </div>
            </div>
            <div style={{ background: colors[i].hover }} className="hover">
              <div
                className="legal-b1-item-flex"
                style={{
                  backgroundImage: `url("/legal/${i + 1}-bg-hover.svg")`,
                  backgroundPosition: "bottom",
                }}
              >
                <div className="font-28 semi-bold name">{name}</div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)
export default function Legal() {
  return (
    <div className="legal">
      <SEO {...data.seo} />
      <div className="legal-topBanner">
        <div className="legal-topBanner-bg full-bg">
          <div className="headerContainer">
            <Header logo="/logo-blue.png" />
          </div>
          <div className="legal-topBanner-content">
            <h1 className="font-56 t-color semi-bold">
              {data.topBanner.title}
            </h1>
            <div
              className="font-20 mt-24 block-middle description"
              style={{ maxWidth: "960px" }}
            >
              {data.topBanner.description}
            </div>
          </div>
        </div>
      </div>
      <B1 />
      <Footer />
    </div>
  )
}
